import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";
import '../assets/styles/pages/_about.scss';

import { StaticImage } from "gatsby-plugin-image"

import { graphql } from "gatsby"

import GallerySlider from '../components/GallerySlider';

export default function about({ data }) {

  const teamList = data.allTeamDataJson.nodes;

  return (
    <Layout>
    <Seo title="About" />
    <div className="about-wrapper">
      <div className="banner-page about">
        <StaticImage
            className="banner-page__image"
            placeholder="blurred"
            src="../assets/images/banner-about.jpeg"
            quality={95}
            alt="banner context"
          />
        <div className="banner-text text-center"><h1>Oral History <br/>Quiénes somos</h1></div>
        <div className="gradient"></div>
      </div>

      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="fw-bold">¿Quiénes somos?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <p>Somos un equipo multidisciplinario, cada uno con vasta experiencia profesional en su especialidad. Buscamos contribuir para que futuras generaciones registren y comprendan lo vivido en la educación escolar durante la pandemia.</p>
            </div>
            <div className="col-12 col-md-6">
              <p>Hemos recopilado el testimonio de más de 30 personas que han sido testigos y protagonistas de lo acontecido en educación en Chile entre los años 2019 y 2022. Si requieres información sobre este proyecto, escríbenos a ldaza@udd.cl </p>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container">
          <div className="row px-3">
            <div className="about-gallery">
              <GallerySlider/>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5 pb-5">
        <div className="container">
          <div className="row justify-content-center gx-3">

            <div className="col-10 col-md-auto">
              <div className="about-list">
                {
                  teamList.map((member, index) => {
                    const teampic = `../../team/${member.picture}`
                    return (
                        <div className="about-item" key={index}>
                            {
                              member.picture !== "" ?
                                <div className="about-item__image">
                                  <img src={teampic} alt={ member.name }/>
                                </div>
                                :
                                <div className="about-item__image">
                                </div>
                            }
                          <div className="about-item__content">
                            <h4>{ member.name }</h4>
                            <p>{ member.position }</p>
                            <p className="more">{ member.grade }</p>
                          </div>
                        </div>
                    )
                  })
                }
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </div>
  </Layout>
  )
}


export const query = graphql`
  {
    allTeamDataJson {
      nodes {
        name
        position
        grade
        picture
      }
    }
  }
`