import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import slide1 from '../assets/images/gallery/slider_1.jpg'
import slide2 from '../assets/images/gallery/slider_2.jpg'
import slide3 from '../assets/images/gallery/slider_3.jpg'
import slide4 from '../assets/images/gallery/slider_4.jpg'
import slide5 from '../assets/images/gallery/slider_5.jpg'
import slide6 from '../assets/images/gallery/slider_6.jpg'
import slide7 from '../assets/images/gallery/slider_7.jpg'
import slide8 from '../assets/images/gallery/slider_8.jpg'


const GallerySlider = () => {
  return (
    <>
    <Swiper
      spaceBetween={50}
      autoplay={false}
      slidesPerView={1}
      navigation={true}
      pagination={true}
      mousewheel={false}
      keyboard={false}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
    >
      <SwiperSlide>
        <img src={slide1} alt="slide 1" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide2} alt="slide 2" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide3} alt="slide 3" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide4} alt="slide 4" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide5} alt="slide 5" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide6} alt="slide 6" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide7} alt="slide 7" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide8} alt="slide 8" />
      </SwiperSlide>
    </Swiper>
    
    </>
  )
}

export default GallerySlider